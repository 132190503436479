<template>
  <el-card>
    <el-tabs v-model="tabActive">
      <el-tab-pane label="求职" name="first">
		  
        <el-form ref="form" :model="form"   label-width="120px" label-suffix="：">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="姓名">
                <el-input v-model="form.name"></el-input>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="性别">
                <el-select v-model="form.sex" placeholder="请选择活动区域">
                  <el-option label="男" value="1"></el-option>
                  <el-option label="女" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="民族">
                <el-input v-model="form.family"></el-input>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="国籍">
             
				
				
				<el-select v-model="form.nationality"  >
				  <el-option :label="item.name" v-for="item in nationalities" :key="item.value"
				             :value="item.value"></el-option>
				
				</el-select>
				
				
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="年龄">
                <el-input v-model="form.age"></el-input>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="婚姻状况">
				  
				<el-select v-model="form.marriage"  >
				  <el-option label="已婚" value="1"></el-option>
				  <el-option label="未婚" value="2"></el-option>
				</el-select>
				
				
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="身高">
                <el-input v-model="form.height"></el-input>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="体重">
				    <el-input v-model="form.weight"></el-input>
                
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学历">
                <el-input v-model="form.education"></el-input>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="专业">
				  				    <el-input v-model="form.major"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所求职位">
                <el-input v-model="form.typework"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="联系方式">
            <el-row>
              <el-col :span="6" class="padding-right">
                <el-input v-model="form.tel" placeholder="手机号码"></el-input>
              </el-col>
              <el-col :span="6" class="padding-right">
                <el-input v-model="form.weixin" placeholder="微信"></el-input>
              </el-col>
              <el-col :span="12">
                <el-input v-model="form.email" placeholder="邮箱"></el-input>
              </el-col>
            </el-row>
          </el-form-item>

     <!--     <el-form-item label="住址">
            <el-row>
              <el-col :span="8" class="padding-right">
                <el-select v-model="form.province" placeholder="请选择活动区域">
                  <el-option label="男" value="shanghai"></el-option>
                  <el-option label="女" value="beijing"></el-option>
                </el-select>
              </el-col>
              <el-col :span="8" class="padding-right">
                <el-select v-model="form.city" placeholder="请选择活动区域">
                  <el-option label="男" value="shanghai"></el-option>
                  <el-option label="女" value="beijing"></el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select v-model="form.area" placeholder="请选择活动区域">
                  <el-option label="男" value="shanghai"></el-option>
                  <el-option label="女" value="beijing"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-input v-model="form.name" class="margin-top" placeholder="请输入详细地址"></el-input>
          </el-form-item> -->
		  
		  <el-form-item label="住址">
		    <el-row class="addr">
		      <el-col :span="8">
		        <el-select v-model="form.currentProvince" @change="getCurrentCities">
		          <el-option :label="item.name" :value="item.id" v-for="item in currentProvinces" :key="item.id">
		          </el-option>
		        </el-select>
		      </el-col>
		      <el-col :span="8">
		        <el-select v-model="form.currentCity" @change="getCurrentDistricts">
		          <el-option :label="item.name" :value="item.id" v-for="item in currentCities" :key="item.id">
		          </el-option>
		  
		        </el-select>
		      </el-col>
		      <el-col :span="8">
		        <el-select v-model="form.currentDistrict">
		          <el-option :label="item.name" :value="item.id" v-for="item in currentDistricts" :key="item.id">
		          </el-option>
		  
		        </el-select>
		      </el-col>
		    </el-row>
		    <div style="margin-top: 10px;">
		      <el-input v-model="form.currentAddress"></el-input>
		    </div>
		  </el-form-item>
		  
		  
          <el-form-item label="个人简要履历">
            <el-input type="textarea" v-model="form.info" :rows="5" maxlength="300" show-word-limit></el-input>
          </el-form-item>

          <el-form-item class="text-center margin-top">
            <el-button @click="sendResume" >1发布新求职信息</el-button>
            <el-button type="text">查看个人求职信息</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
	  
	  
	  
	  
      <el-tab-pane label="招聘" name="second" >
        <el-form ref="form2" :model="form2" @submit.native.prevent="" label-width="160px" label-suffix="：">
          <p class="title">招聘要求</p>
          <el-row :gutter="20" class="margin-top-lg">
            <el-col :span="12">
              <el-form-item label="年龄">
                <el-input v-model="form2.age"></el-input>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="专业">
				<el-input v-model="form2.major"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="工作年限">
                <el-input v-model="form2.years"></el-input>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="职称">
				     <el-input v-model="form2.position"></el-input>
                
              </el-form-item>
            </el-col>
          </el-row> 
		  
		  <el-form-item label="工作地址">
		    <el-row class="addr">
		      <el-col :span="8">
		        <el-select v-model="form2.currentProvince2" @change="getCurrentCities2">
		          <el-option :label="item.name" :value="item.id" v-for="item in currentProvinces" :key="item.id">
		          </el-option>
		        </el-select>
		      </el-col>
		      <el-col :span="8">
		        <el-select v-model="form2.currentCity2" @change="getCurrentDistricts2">
		          <el-option :label="item.name" :value="item.id" v-for="item in currentCities2" :key="item.id">
		          </el-option>
		  
		        </el-select>
		      </el-col>
		      <el-col :span="8">
		        <el-select v-model="form2.currentDistrict2">
		          <el-option :label="item.name" :value="item.id" v-for="item in currentDistricts2" :key="item.id">
		          </el-option>
		  
		        </el-select>
		      </el-col>
		    </el-row>
		    <div style="margin-top: 10px;">
		      <el-input v-model="form2.currentAddress2"></el-input>
		    </div>
		  </el-form-item>
		  
		  
          <el-form-item label="任职要求">
            <el-input type="textarea" v-model="form2.jobrequirements" :rows="5" maxlength="300" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="公司介绍">
            <el-input type="textarea" v-model="form2.companyintroduction" :rows="5" maxlength="300" show-word-limit></el-input>
          </el-form-item>

          <p class="title">工商信息</p>
          <el-row :gutter="20" class="margin-top-lg">
            <el-col :span="12">
              <el-form-item label="法定代表人">
                <el-input v-model="form2.representative"></el-input>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="注册资本">
				  
				  				<el-input v-model="form2.capital"></el-input>
                
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="成立日期">
				
				<el-date-picker
				    v-model="form2.dateoin"
				    type="date"
				    placeholder="选择日期"
					format="yyyy-MM-dd"
					>
				</el-date-picker>
				
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="企业类型">
				  				<el-input v-model="form2.enterprisetype"></el-input>
           
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="经营状态">
                <el-input v-model="form2.operating_status"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          
		  
		 
		  
		  
		  
		  <el-form-item label="注册地址">
		    <el-row class="addr">
		      <el-col :span="8">
		        <el-select v-model="form2.currentProvince3" @change="getCurrentCities3">
		          <el-option :label="item.name" :value="item.id" v-for="item in currentProvinces" :key="item.id">
		          </el-option>
		        </el-select>
		      </el-col>
		      <el-col :span="8">
		        <el-select v-model="form2.currentCity3" @change="getCurrentDistricts3">
		          <el-option :label="item.name" :value="item.id" v-for="item in currentCities3" :key="item.id">
		          </el-option>
		  
		        </el-select>
		      </el-col>
		      <el-col :span="8">
		        <el-select v-model="form2.currentDistrict3">
		          <el-option :label="item.name" :value="item.id" v-for="item in currentDistricts3" :key="item.id">
		          </el-option>
		  
		        </el-select>
		      </el-col>
		    </el-row>
		    <div style="margin-top: 10px;">
		      <el-input v-model="form2.currentAddress3"></el-input>
		    </div>
		  </el-form-item>
		  

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="统一社会信用代码">
                <el-input v-model="form2.code2"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="经营范围">
            <el-input type="textarea" v-model="form2.natureofbusiness" :rows="5" maxlength="300" show-word-limit></el-input>
          </el-form-item>

          <el-form-item class="text-center">
            <el-button   @click="sendResume2" >1发布招聘信息</el-button>
            <el-button type="text">查看招聘信息</el-button>
          </el-form-item>


        </el-form>




      </el-tab-pane>
    </el-tabs>

  </el-card>
  
</template>

<script>
  import axios from "axios";  
  export default {
    name: "RecruitmentIndex",
    data() {
      return {
        tabActive: 'first',
        form: {},
        form2: {},
		races: [{value: 1, name:'汉族'}],
		hometowns: [],
		homeProvinces: [],
		homeCities: [],
		homeDistricts: [],
		currentProvinces: [],
		currentCities: [],
		currentDistricts: [],
		currentCities2: [],
		currentDistricts2: [],
		currentCities3: [],
		currentDistricts3: [],
		nationalities: [{value:1,name:'中国'}], 
		
      }
    },  
	created(){
		const formData = new FormData() 
		//这里是把上传接口所有的参数都append到formData中
		formData.append('type', 1)
		const config = {
		    headers: {
		        'Content-Type': 'multipart/form-data'
		    }
		} 
		axios.post(this.apiUrl+'/api/tool/provs', formData, config).then((res) => { 
			this.hometowns = this.homeProvinces = this.currentProvinces = res.data.data;  
		})
	},
	methods: { 
		getCurrentCities3(e) {
				 const formData = new FormData()
				 //这里是把上传接口所有的参数都append到formData中
				 formData.append('id', this.form2.currentProvince3)
				 const config = {
				     headers: {
				         'Content-Type': 'multipart/form-data'
				     }
				 } 
				 axios.post(this.apiUrl+'/api/tool/citys', formData, config).then((res) => { 
				 	this.currentCities3 = res.data.data; 
				 }) 	
		}, 
		getCurrentDistricts3(e) {  
				  const formData = new FormData()
				  //这里是把上传接口所有的参数都append到formData中
				  formData.append('id', this.form2.currentCity3)
				  const config = {
				      headers: {
				          'Content-Type': 'multipart/form-data'
				      }
				  } 
				  axios.post(this.apiUrl+'/api/tool/areas', formData, config).then((res) => { 
				  	this.currentDistricts3 = res.data.data; 
				  }) 
		},
		getCurrentCities2(e) {
				 const formData = new FormData()
				 //这里是把上传接口所有的参数都append到formData中
				 formData.append('id', this.form2.currentProvince2)
				 const config = {
				     headers: {
				         'Content-Type': 'multipart/form-data'
				     }
				 } 
				 axios.post(this.apiUrl+'/api/tool/citys', formData, config).then((res) => { 
				 	this.currentCities2 = res.data.data; 
				 }) 
		}, 
		getCurrentDistricts2(e) {  
				  const formData = new FormData()
				  //这里是把上传接口所有的参数都append到formData中
				  formData.append('id', this.form2.currentCity2)
				  const config = {
				      headers: {
				          'Content-Type': 'multipart/form-data'
				      }
				  } 
				  axios.post(this.apiUrl+'/api/tool/areas', formData, config).then((res) => { 
				  	this.currentDistricts2 = res.data.data; 
				  }) 
		},
		getCurrentCities(e) {  
				 const formData = new FormData()
				 //这里是把上传接口所有的参数都append到formData中
				 formData.append('id', this.form.currentProvince)
				 const config = {
				     headers: {
				         'Content-Type': 'multipart/form-data'
				     }
				 } 
				 axios.post(this.apiUrl+'/api/tool/citys', formData, config).then((res) => { 
				 	this.currentCities = res.data.data; 
				 }) 
		}, 
		getCurrentDistricts(e) {  
				  const formData = new FormData()
				  //这里是把上传接口所有的参数都append到formData中
				  formData.append('id', this.form.currentCity)
				  const config = {
				      headers: {
				          'Content-Type': 'multipart/form-data'
				      }
				  } 
				  axios.post(this.apiUrl+'/api/tool/areas', formData, config).then((res) => { 
				  	this.currentDistricts = res.data.data; 
				  }) 
		},
		sendResume2() {
			
			const params2 = new URLSearchParams(); 
			params2.append('uid', sessionStorage.getItem('uid'));
			params2.append('age', this.form2.age);
			params2.append('major', this.form2.major);
			params2.append('years', this.form2.years);
			params2.append('position', this.form2.position);
			
 
			params2.append('province', this.form2.currentProvince2);
			params2.append('city', this.form2.currentCity2);
			params2.append('area', this.form2.currentDistrict2);
			params2.append('info', this.form2.currentAddress2); 
			
			
			
			params2.append('representative', this.form2.representative);
			params2.append('capital', this.form2.capital);
			params2.append('dateoin', this.form2.dateoin);
			params2.append('enterprisetype', this.form2.enterprisetype);
			params2.append('operating_status', this.form2.operating_status);
			
			 
			params2.append('province2', this.form2.currentProvince3);
			params2.append('city2', this.form2.currentCity3);
			params2.append('area2', this.form2.currentDistrict3);
			params2.append('info2', this.form2.currentAddress3); 
			
			
			
			
			params2.append('code2', this.form2.code2);
			params2.append('natureofbusiness', this.form2.natureofbusiness);
			params2.append('jobrequirements', this.form2.jobrequirements);
			params2.append('companyintroduction', this.form2.companyintroduction);
 
			
			
			
			
			axios.post(this.apiUrl+'/api/user/setRecruit',params2)
			.then((response) => {    	
				this.$message.success('操作成功！');
			}) 
			.catch(function(error) {
			  console.log(error);
			});
		},
		sendResume() { 
		  //医生求职
		  const params = new URLSearchParams();
		  params.append('uid', sessionStorage.getItem('uid')); 
		  params.append('name', this.form.name);
		  params.append('sex', this.form.sex);
		  params.append('family', this.form.family);
		  params.append('country', this.form.nationality);
		  params.append('age', this.form.age);
		  params.append('marriage', this.form.marriage);
		  params.append('height', this.form.height);
		  params.append('weight', this.form.weight);
		  params.append('education', this.form.education);
		  params.append('major', this.form.major);
		  params.append('typework', this.form.typework);
		  params.append('tel', this.form.tel);
		  params.append('weixin', this.form.weixin);
		  params.append('email', this.form.email);
		  
		  params.append('province', this.form.currentProvince);
		  params.append('city', this.form.currentCity);
		  params.append('area', this.form.currentDistrict);
		  params.append('info', this.form.currentAddress); 
		  
		  
		  axios.post(this.apiUrl+'/api/user/setResume',params)
		  .then((response) => {    
			  	this.$message.success('操作成功！');
		  }) 
		  .catch(function(error) {
		    console.log(error);
		  });
		}
	}
  }
</script>

<style scoped lang="scss">

  .el-card {
    min-height: 1050px;

    .el-form {
      padding: 10px 80px;
      /deep/ .title{
        font-size: 16px;
        margin-left: -10px;
      }

      .margin-top-lg{
        margin-top: 20px;
      };

      .el-select {
        width: 100%;
      }



      .padding-right {
        padding-right: 10px;
      }

      .margin-top {
        margin-top: 10px;
      }

      .el-button {
        margin-right: 20px;

        &:first-child {
          margin-left: 40px;
        }

        &.el-button--text {
          color: $green;
        }
      }
    }


  }
</style>
